// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar.css */
.sidebar {
  margin-top: 30px;
  width: 180px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
}

.sidebar-item {
  display: flex;
  gap: 10px;
  color: #afafaf !important;
  margin-bottom: 20px !important;
  font-weight: bold;
  &:hover {
    color: #ad6b46 !important;
  }
  &.active-en {
    color: #ad6b46 !important;
    border-right: 3px solid #ad6b46;
  }
  &.active-ar {
    color: #ad6b46 !important;
    border-left: 3px solid #ad6b46;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,iDAAiD;EACjD,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,yBAAyB;EACzB,8BAA8B;EAC9B,iBAAiB;EACjB;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;IACzB,+BAA+B;EACjC;EACA;IACE,yBAAyB;IACzB,8BAA8B;EAChC;AACF","sourcesContent":["/* Sidebar.css */\n.sidebar {\n  margin-top: 30px;\n  width: 180px;\n  background-color: #ffffff;\n  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n  border-radius: 5px;\n}\n\n.sidebar-item {\n  display: flex;\n  gap: 10px;\n  color: #afafaf !important;\n  margin-bottom: 20px !important;\n  font-weight: bold;\n  &:hover {\n    color: #ad6b46 !important;\n  }\n  &.active-en {\n    color: #ad6b46 !important;\n    border-right: 3px solid #ad6b46;\n  }\n  &.active-ar {\n    color: #ad6b46 !important;\n    border-left: 3px solid #ad6b46;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
