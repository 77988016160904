// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  width: 100%;
  /* margin: 20px; */
  overflow-x: auto;
}

.stunning-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* white-space: nowrap;  */
}

.stunning-table th,
.stunning-table td {
  padding: 12px 15px;
  text-align: left;
}

.stunning-table th {
  background-color: #ad6b46;
  color: #fff;
}

.stunning-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
.stunning-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}
.stunning-table tbody tr:hover {
  background-color: #e0e0e0;
}

/* Responsive styles */
@media (max-width: 767px) {
  .table-container {
    overflow-x: auto; /* Enable horizontal scrolling for small screens */
  }

  .stunning-table {
    font-size: 14px; /* Reduce font size for small screens */
  }

  .stunning-table th,
  .stunning-table td {
    padding: 8px 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,wCAAwC;EACxC,0BAA0B;AAC5B;;AAEA;;EAEE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;;AAEA,sBAAsB;AACtB;EACE;IACE,gBAAgB,EAAE,kDAAkD;EACtE;;EAEA;IACE,eAAe,EAAE,uCAAuC;EAC1D;;EAEA;;IAEE,iBAAiB;EACnB;AACF","sourcesContent":[".table-container {\n  width: 100%;\n  /* margin: 20px; */\n  overflow-x: auto;\n}\n\n.stunning-table {\n  width: 100%;\n  border-collapse: collapse;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  /* white-space: nowrap;  */\n}\n\n.stunning-table th,\n.stunning-table td {\n  padding: 12px 15px;\n  text-align: left;\n}\n\n.stunning-table th {\n  background-color: #ad6b46;\n  color: #fff;\n}\n\n.stunning-table tbody tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n.stunning-table tbody tr:nth-child(odd) {\n  background-color: #f9f9f9;\n}\n.stunning-table tbody tr:hover {\n  background-color: #e0e0e0;\n}\n\n/* Responsive styles */\n@media (max-width: 767px) {\n  .table-container {\n    overflow-x: auto; /* Enable horizontal scrolling for small screens */\n  }\n\n  .stunning-table {\n    font-size: 14px; /* Reduce font size for small screens */\n  }\n\n  .stunning-table th,\n  .stunning-table td {\n    padding: 8px 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
